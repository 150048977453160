<template>
    <div class="locker-loading-container">
        <div v-if="availableLockersRegions.length > 0 && selectedLocker == null" class="region-filter">
            <el-form-item :label="$t('sales.order.labels.lockerregion')" prop="sender.zip">
                <el-select
                    v-model="lockersRegion"
                    filterable
                    placeholder="Localitate"
                    @change="onLockerRegionSelect"
                    class="fullWidth"
                >
                    <el-option
                        v-for="item in availableLockersRegions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                    >
                    </el-option>
                </el-select>
            </el-form-item>
        </div>

        <div class="region-filter no-regions" v-else-if="availableLockersRegions.length == 0">
            Acest curier nu dispune de puncte de incarcare
        </div>

        <div class="locker-selected" v-if="selectedLocker">
            <div class="locker-select-container">
                <div class="locker-select-icon green">
                    <svg-icon icon-class="truck" />
                </div>
                <div>
                    <div class="name">{{ selectedLocker.name }}</div>
                    <div class="address">{{ selectedLocker.address }}</div>
                </div>
            </div>
            <div class="remove-locker">
                <el-button type="text" icon="el-icon-close" @click="removeLocker"></el-button>
            </div>
        </div>

        <div v-if="originalCourierLockers.length > 0 && selectedLocker == null">
            <el-form-item :label="$t('sales.order.labels.locker')" prop="sender.zip">
                <div class="locker-filter" v-if="originalCourierLockers.length > 5">
                    <el-input
                        placeholder="Cauta locker"
                        v-model="lockerFilterInput"
                        @input="onLockerFilterInput"
                        clearable
                    ></el-input>
                </div>
                <ul class="locker-select">
                    <li
                        class="locker-select-item"
                        v-for="(item, index) in courierLockers"
                        :key="item.id"
                        @click="selectLocker(item)"
                    >
                        <div class="locker-select-container">
                            <div class="locker-select-icon">
                                <svg-icon icon-class="truck" />
                            </div>
                            <div>
                                <div class="name">{{ item.name }}</div>
                                <div class="address">{{ item.address }}</div>
                            </div>
                        </div>
                    </li>
                </ul>
            </el-form-item>
        </div>
    </div>
</template>

<script>
import { sync, call, get } from 'vuex-pathify';
import debounce from 'lodash.debounce';

export default {
    props: {
        lockerID: {
            type: Number,
            default: 0,
        },
    },
    data: () => ({
        lockerFilterInput: '',
        selectedLocker: null,
        selectedLockerId: 0,
        lockersRegion: null,
        courierLockers: [],
        originalCourierLockers: [],
    }),
    computed: {
        lockersRegions: get('sales/order@awb.lockersRegions'),
        ...sync('sales/order@awb.form', {
            courierID: 'courier_id',
        }),
        availableLockersRegions() {
            const courierLockersRegions = this.lockersRegions.find((item) => item.courierId == this.courierID);
            if (courierLockersRegions == null) {
                return [];
            }
            return courierLockersRegions.lockers.map((item) => {
                const value = btoa(`${item.region}+${item.town}`);
                return {
                    value,
                    label: `${item.town}, ${item.region} (${item.availablePoints})`,
                };
            });
        },
    },
    methods: {
        getCourierLockers: call('sales/GetCourierLockers'),
        getLockersRegions: call('sales/GetLockersRegions'),
        getLockerDetails: call('sales/GetLockerDetails'),
        selectLocker(locker) {
            this.selectedLocker = locker;
            this.selectedLockerId = locker.pickupId;
        },
        // Filter lockers by name. Also debounce the input
        onLockerFilterInput: debounce(function () {
            if (this.lockerFilterInput === '') {
                this.courierLockers = this.originalCourierLockers; // Reset to original list
            } else if (this.courierLockers) {
                this.courierLockers = this.originalCourierLockers.filter((locker) => {
                    return (
                        locker.name.toLowerCase().includes(this.lockerFilterInput.toLowerCase()) ||
                        locker.address.toLowerCase().includes(this.lockerFilterInput.toLowerCase())
                    );
                });
            }
        }, 300),
        async onLockerRegionSelect(value) {
            const lockers = await this.getCourierLockers({
                courierId: this.courierID,
                regionId: value,
            }).catch(() => {});
            this.courierLockers = lockers;
            this.originalCourierLockers = Object.freeze(lockers);
        },
        removeLocker() {
            this.selectedLocker = null;
            this.selectedLockerId = 0;
        },
        reset() {
            this.selectedLocker = null;
            this.selectedLockerId = 0;
            this.lockersRegion = null;
            this.courierLockers = [];
            this.originalCourierLockers = [];
        },
        async setLocker() {
            this.selectedLockerId = this.lockerID;
            if (this.lockerID > 0) {
                const lockerDetails = await this.getLockerDetails({
                    lockerId: this.lockerID,
                    courierId: this.courierID,
                });
                if (lockerDetails == null || lockerDetails.courier_id != this.courierID) {
                    this.$emit('invalidPresetLocker');
                    return false;
                }

                const lockers = await this.getCourierLockers({
                    courierId: this.courierID,
                    regionId: window.btoa(`${lockerDetails.region}+${lockerDetails.town}`),
                });

                this.originalCourierLockers = Object.freeze(lockers);
                this.lockersRegion = window.btoa(`${lockerDetails.region}+${lockerDetails.town}`);

                this.selectedLocker = lockerDetails;
                this.courierLockers = lockers;
                this.$emit('lockerChanged', { id: this.selectedLockerId, locker: this.selectedLocker });
            }
        },
    },
    watch: {
        selectedLockerId(val) {
            this.$emit('lockerChanged', { id: val, locker: this.selectedLocker });
        },
        async courierID(val) {
            this.reset();
            await this.setLocker();
        },
    },
    async created() {
        await this.getLockersRegions({ courierId: this.courierID }).catch(() => {});
        this.onLockerFilterInput = debounce(() => {
            if (this.lockerFilterInput === '') {
                this.courierLockers = this.originalCourierLockers; // Reset to original list
            } else if (this.courierLockers) {
                this.courierLockers = this.originalCourierLockers.filter((locker) => {
                    return (
                        locker.name.toLowerCase().includes(this.lockerFilterInput.toLowerCase()) ||
                        locker.address.toLowerCase().includes(this.lockerFilterInput.toLowerCase())
                    );
                });
            }
        }, 300);
    },
    async mounted() {
        if (this.lockerID != 0) {
            await this.setLocker();
        }
    },
};
</script>

<style scoped>
.region-filter {
    margin-top: 20px;
}

.region-filter.no-regions {
    font-style: italic;
    font-weight: 500;
}

.locker-filter {
    margin-bottom: 10px;
}

.locker-select-container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.locker-select-icon {
    margin-right: 10px;
    font-size: 1.2rem;
}

.locker-select-icon.green {
    color: #4caf50;
}

.locker-select {
    margin: 0;
    padding: 10px;
    list-style-type: none;
    border: 1px solid rgb(180 188 204 / 43%);
    border-radius: 5px;
    max-height: 350px;
    overflow-y: scroll;
    background-color: #fff;
}

ul.locker-select li {
    cursor: pointer;
    line-height: 1.5;
    padding: 10px;
}

ul.locker-select li:not(:last-child) {
    border-bottom: 1px solid rgb(180 188 204 / 43%);
}

.locker-select-container .address {
    font-size: 12px;
    color: #666;
}

.locker-select-container .name {
    font-weight: 600;
}

.locker-select li:hover {
    background-color: #f5f7fa;
}

.locker-selected {
    margin-top: 10px;
    border: 1px solid #4caf50;
    border-radius: 5px;
    padding: 10px 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #f0f9eb;
}

::v-deep .remove-locker i {
    font-size: 20px;
    color: #4caf50;
}
</style>
